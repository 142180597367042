const userApiUrl = `${process.env.REACT_APP_USER_RESOURCE_API_URL}/users`;
const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

export const postUser = ({ authorization, firstName, lastName, email }) =>
  fetch(userApiUrl, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: authorization,
    },
    body: JSON.stringify({ firstName, lastName, email }),
  });

export const patchUser = ({ authorization, password, userId }) =>
  fetch(`${userApiUrl}/${userId}/password`, {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: authorization,
    },
    body: JSON.stringify({ password }),
  });

export const postUserPasswordReset = email =>
  fetch(`${userApiUrl}/password/reset`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ mail: email }),
  });

export const validateTokenApi = (userId, tokenId) =>
  fetch(`${userApiUrl}/${userId}/token/${tokenId}`, {
    headers: {
      ...headers,
    },
  });

export default { patchUser, postUser, postUserPasswordReset, validateTokenApi };
