import styled from 'styled-components';
import { assets, Paper } from 'timeone-components';

import Header from '../Header';

const { InformationIcon: TocInformationIcon } = assets.icons;

export const FormPasswordContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const FormPasswordHeader = styled(Header)`
  margin-bottom: 2.75rem;
`;

export const PasswordValidityConditions = styled.ul`
  margin: 0 1rem;
  text-align: left;
  display: flex;
`;

export const PasswordCreationCard = styled(Paper)`
  width: 26.5rem;
  border-radius: 12px;
  padding: 1rem;
`;

export const InputsContainer = styled.div`
  padding: 1.5rem 2rem 1.5rem;

  label {
    text-align: left;
  }
`;

export const InformationIcon = styled(TocInformationIcon)`
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.blue};
`;
