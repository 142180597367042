import React from 'react';
import styled from 'styled-components';
import { Paper } from 'timeone-components';

import { ButtonSubmit as BtSubmit } from '../components';
import { TimeoneLarge } from '../../assets';

const defaultValues = {
  titleFontSize: '1.75rem',
  titleMargin: '6rem 0 2rem',
  buttonSubmitMagin: 0,
  buttonSubmitFontSize: '0.75rem',
};

const mobileValues = {
  titleFontSize: '1.5rem',
  titleMargin: '1rem  0',
  buttonSubmitMagin: '1rem',
  buttonSubmitFontSize: '1rem',
};

const getResponsiveValues = query => (query === 'extraSmall' ? mobileValues : defaultValues);

export const LoginContainer = styled.div`
  float: left;
  padding: 2rem;
  width: calc(50% - 4rem);
`;

export const MobileLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 2rem);
  min-height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  min-width: calc(100% - 2rem);
  padding: 1rem;
`;

export const LogoTimeoneLarge = styled(TimeoneLarge)`
  width: 12rem;
  max-width: 12rem;
  min-width: 12rem;
  min-height: 4.25rem;
  height: 4.25rem;
`;

export const LogoTimeoneLargeMobileContainer = styled.div`
  align-self: center;
  padding-bottom: 1rem;
`;

export const Overlay = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const Popup = styled(Paper)`
  height: 16rem;
  width: 20rem;
`;

export const Header = styled.header`
  padding: 2rem 0;
  position: relative;
`;

export const ErrorInformations = styled.p`
  bottom: 2rem;
  color: ${({ theme }) => theme.colors.error};
  left: 0;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ErrorInformationsMobile = styled.p`
  color: ${({ theme }) => theme.colors.error};
`;

export const Title = styled.h1.attrs(({ query }) => ({
  responsiveValues: getResponsiveValues(query),
}))`
  font-size: ${({ responsiveValues }) => responsiveValues.titleFontSize};
  margin: ${({ responsiveValues }) => responsiveValues.titleMargin};
  text-transform: capitalize;
`;

export const InputsContainer = styled.div`
  display: flex;

  & > label {
    margin: 0 1rem;
  }
`;

export const MobileInputsContainer = styled.div`
  .input-text {
    margin-bottom: 2rem;
  }
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  margin: 2rem 0 0;
  justify-content: flex-end;
  width: 100%;
`;

export const ForgotPassword = styled.button`
  margin: 0 1rem;
`;

export const ButtonSubmit = styled(({ query, responsiveValues, ...rest }) => <BtSubmit {...rest} />).attrs(
  ({ query }) => ({
    responsiveValues: getResponsiveValues(query),
  })
)`
  margin: ${({ responsiveValues }) => responsiveValues.buttonSubmitMagin};
  font-size: ${({ responsiveValues }) => responsiveValues.buttonSubmitFontSize};
  padding: 0.5rem 1.5rem;
`;

export const Footer = styled.footer`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 2rem;
  position: absolute;
  width: calc(50% - 4rem);
`;

export const SocialsLinksContainer = styled.div`
  display: flex;
`;

export const SocialLink = styled.a`
  &:nth-child(2) {
    margin: 0 1rem;
  }

  svg {
    height: 1.75rem;
    width: 1.75rem;
  }
`;

export const Copyright = styled.span`
  font-size: 0.875rem;
`;

export const ImageRight = styled.div`
  background-image: url('/assets/Login.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  float: right;
  height: 100%;
  width: 50%;
`;
