import React, { useEffect, useState } from 'react';
import { Loader } from 'timeone-components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { LoaderContainer } from './styledComponents';
import { validateToken } from '../../../services/user/user.service';
import DisplayForm from '../DisplayForm/DisplayForm';

const FormPassword = ({ title, description, successTitle, successDescription, history }) => {
  const [isTokenValid, setIsTokenValid] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    async function shouldDisplayForm() {
      try {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        const userId = searchParams.get('userId');
        const tokenContent = await validateToken(userId, token);

        if (tokenContent?.isActive) {
          setIsLoading(false);
          setIsTokenValid(true);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
    shouldDisplayForm();
  }, []);

  return !isLoading ? (
    <DisplayForm
      title={title}
      description={description}
      successTitle={successTitle}
      successDescription={successDescription}
      isTokenValid={isTokenValid}
      history={history}
    />
  ) : (
    <LoaderContainer>
      <Loader type="Oval" height="50" width="50" />
    </LoaderContainer>
  );
};

FormPassword.defaultProps = {
  description: [''],
  title: '',
  successTitle: '',
  successDescription: '',
};

FormPassword.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  successTitle: PropTypes.string,
  successDescription: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default FormPassword;
