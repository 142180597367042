import styled from 'styled-components';
import { assets, Button } from 'timeone-components';

const { CheckIcon: TocCheckIcon } = assets.icons;

export const SuccessContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem auto;
  text-align: center;
  width: 100%;
`;

export const CheckIcon = styled(TocCheckIcon)`
  height: 2rem;
  width: 2rem;
`;

export const SuccessTitle = styled.h2`
  font-size: 2rem;
  font-weight: normal;
`;

export const ButtonSuccess = styled(Button)`
  font-size: 0.875rem;
  margin: 2rem auto;
  transition: all 0.25s;

  &:hover {
    opacity: 0.75;
  }
`;
