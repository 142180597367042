export const authenticate = ({ email, password }) => {
  const formData = new FormData();

  formData.append('email', email);
  formData.append('password', password);

  return fetch(`${process.env.REACT_APP_OAUTH2_SERVER_URL}/login`, {
    credentials: 'include',
    method: 'POST',
    body: formData,
  });
};

export default { authenticate };
