import styled from 'styled-components';

export const Title = styled.h1`
  text-transform: initial;
  font-size: 2rem;
  margin: 2rem auto;
`;

export const DescriptionsContainer = styled.div`
  line-height: 1.375rem;
  font-size: 1rem;
  padding: 0.125rem 0;
  text-align: center;

  p {
    text-align: center;
  }
`;
