import React, { useState, useEffect } from 'react';
import { assets, Form, InputText } from 'timeone-components';
import OutsideClickHandler from 'react-outside-click-handler';
import CSSTransition from 'react-transition-group/CSSTransition';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from 'react-device-detect';

import { userService } from '../../../../services';
import commonPropTypes from '../../../../utils/commonPropTypes';
import {
  ButtonSubmit,
  CloseButton,
  FormButtonsContainer,
  Header,
  Informations,
  Overlay,
  Popup,
  PopupStatusMessage,
  Title,
} from './styledComponents';
import validateMail from '../../../../utils/validateMail';

const { CloseIcon } = assets.icons;

let setTimeoutOnCloseClick;

const ForgotPasswordPopup = ({ display, onCloseClick, onOutsideClick }) => {
  const [isStatutsMessage, setIsStatutsMessage] = useState(null);
  const [statusMessageType, setStatusMessageType] = useState(null);
  const [statusMessage, setStatusMessage] = useState();

  useEffect(() => () => clearTimeout(setTimeoutOnCloseClick), []);

  const handleSubmit = async ({ forgotEmail }) => {
    try {
      const result = await userService.resetPassword(forgotEmail);

      if (result === 'success') {
        setTimeoutOnCloseClick = setTimeout(() => {
          onCloseClick();
        }, 5000);

        setStatusMessageType('success');
        setIsStatutsMessage(true);
        setStatusMessage(
          `We sent a link to ${forgotEmail} so you can pick your new password.\n Didn’t get the email? Check your email address.`
        );
        return undefined;
      }
      throw new Error('Error when trying to send a resetPassword.');
    } catch (error) {
      console.error(error.message);
      setStatusMessageType('error');
      setIsStatutsMessage(true);
      setStatusMessage('An error occured. Please try again.');

      return { errors: error.message };
    }
  };

  const renderButtons = ({ submitting, submitSucceeded }) => {
    return (
      <FormButtonsContainer>
        {isStatutsMessage && statusMessageType === 'error' ? (
          <PopupStatusMessage type={statusMessageType}>{statusMessage}</PopupStatusMessage>
        ) : null}
        <ButtonSubmit submitting={submitting} disabled={submitSucceeded || submitting} fill primary>
          Send Email
        </ButtonSubmit>
      </FormButtonsContainer>
    );
  };

  renderButtons.propTypes = commonPropTypes.renderButtons.propTypes;
  return (
    <CSSTransition in={display} classNames="fade" timeout={0}>
      <Overlay>
        <OutsideClickHandler onOutsideClick={display ? onOutsideClick : () => {}}>
          <Popup query={isMobile && !isTablet ? 'extraSmall' : 'medium'}>
            <Header>
              <div>
                <Title>Forgot your password ?</Title>
                <Informations>Enter your Email.</Informations>
                <Informations>We&lsquo;ll email instructions on how to reset your password.</Informations>
              </div>
              <CloseButton type="button" onClick={onCloseClick}>
                <CloseIcon />
              </CloseButton>
            </Header>
            <Form onSubmit={handleSubmit} renderButtons={renderButtons}>
              {isStatutsMessage && statusMessageType === 'success' ? (
                <>
                  <br />
                  <PopupStatusMessage type={statusMessageType}>{statusMessage}</PopupStatusMessage>
                </>
              ) : null}
              {isMobile || isTablet ? (
                <InputText label="Email" name="forgotEmail" type="email" validation={validateMail} required />
              ) : (
                <InputText label="Email" name="forgotEmail" type="text" validation={validateMail} required />
              )}
            </Form>
          </Popup>
        </OutsideClickHandler>
      </Overlay>
    </CSSTransition>
  );
};

ForgotPasswordPopup.defaultProps = {
  display: true,
  onCloseClick: () => {},
  onOutsideClick: () => {},
};

ForgotPasswordPopup.propTypes = {
  display: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
};

export default ForgotPasswordPopup;
