import React, { PureComponent } from 'react';
import { Form, InputText, InputPassword, GlobalStyle } from 'timeone-components';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from 'react-device-detect';

import { authenticationService } from '../../services';
import { IcLinkedin, IcFacebook, IcTwitter } from '../../assets';

import { ForgotPasswordPopup } from './components';
import {
  ButtonSubmit,
  Copyright,
  ErrorInformations,
  ErrorInformationsMobile,
  Footer,
  ForgotPassword,
  FormButtonsContainer,
  Header,
  ImageRight,
  InputsContainer,
  LoginContainer,
  LogoTimeoneLarge,
  LogoTimeoneLargeMobileContainer,
  MobileInputsContainer,
  MobileLoginContainer,
  SocialLink,
  SocialsLinksContainer,
  Title,
} from './styledComponents';
import validateMail from '../../utils/validateMail';

class Login extends PureComponent {
  state = {
    error: null,
    shouldDisplayForgotPasswordPopup: false,
  };

  inputsEmail = React.createRef();

  componentDidMount() {
    if (this.inputsEmail && this.inputsEmail.current) {
      this.inputsEmail.current.focus();
    }
  }

  toggleForgotPasswordPopup = () =>
    this.setState(prevState => ({
      shouldDisplayForgotPasswordPopup: !prevState.shouldDisplayForgotPasswordPopup,
    }));

  renderButtons = ({ submitting }) => (
    <FormButtonsContainer>
      <ForgotPassword type="button" onClick={this.toggleForgotPasswordPopup}>
        Forgot Password ?
      </ForgotPassword>
      <ButtonSubmit
        submitting={submitting}
        fill="true"
        primary
        type="submit"
        query={isMobile && !isTablet ? 'extraSmall' : 'medium'}
        style={{ backgroundColor: '#01083B' }}
      >
        Log In
      </ButtonSubmit>
    </FormButtonsContainer>
  );

  handleSubmit = async values => {
    const { location } = this.props;

    try {
      const result = await authenticationService.loginUser(values);

      if (result === 'success') {
        const searchParams = new URLSearchParams(location.search);
        const redirectUriParam = searchParams.get('redirect_uri');
        const authenticationUrl = `${process.env.REACT_APP_IDENTIFICATIONV2_URL}/oauth`;
        const redirectUri = redirectUriParam || process.env.REACT_APP_LOGIN_REDIRECT_URL;

        window.location.assign(`${authenticationUrl}?redirect_uri=${redirectUri}`);
        return;
      }

      throw new Error('Error when trying to authenticate user.');
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  render() {
    const { error, shouldDisplayForgotPasswordPopup } = this.state;

    return isMobile && !isTablet ? (
      <>
        <MobileLoginContainer>
          <LogoTimeoneLargeMobileContainer query={isMobile && !isTablet ? 'extraSmall' : 'medium'}>
            <LogoTimeoneLarge />
          </LogoTimeoneLargeMobileContainer>
          <Title query={isMobile && !isTablet ? 'extraSmall' : 'medium'}>Sign In</Title>
          {error && <ErrorInformationsMobile>{error}</ErrorInformationsMobile>}
          <Form onSubmit={this.handleSubmit} renderButtons={this.renderButtons}>
            <MobileInputsContainer>
              <InputText
                type="email"
                label="Email"
                name="email"
                className="input-text"
                validation={validateMail}
                required
                config={{ ref: this.inputsEmail }}
              />
              <InputPassword
                allowPaste
                type="password"
                label="Password"
                name="password"
                className="input-password"
                required
              />
            </MobileInputsContainer>
          </Form>
          {shouldDisplayForgotPasswordPopup ? (
            <ForgotPasswordPopup
              display={shouldDisplayForgotPasswordPopup}
              onCloseClick={this.toggleForgotPasswordPopup}
              onOutsideClick={this.toggleForgotPasswordPopup}
            />
          ) : null}
        </MobileLoginContainer>
        <GlobalStyle />
      </>
    ) : (
      <>
        <LoginContainer>
          <Header>
            <LogoTimeoneLarge />
            <Title>Sign In</Title>
            {error && <ErrorInformations>{error}</ErrorInformations>}
          </Header>
          <Form onSubmit={this.handleSubmit} renderButtons={this.renderButtons}>
            <InputsContainer>
              <InputText
                type="text"
                label="Email"
                name="email"
                className="input-text"
                validation={validateMail}
                required
                config={{ ref: this.inputsEmail }}
              />
              <InputPassword
                allowPaste
                type="password"
                label="Password"
                name="password"
                className="input-password"
                required
              />
            </InputsContainer>
          </Form>
          {shouldDisplayForgotPasswordPopup ? (
            <ForgotPasswordPopup
              display={shouldDisplayForgotPasswordPopup}
              onCloseClick={this.toggleForgotPasswordPopup}
              onOutsideClick={this.toggleForgotPasswordPopup}
            />
          ) : null}
          <Footer>
            <SocialsLinksContainer>
              <SocialLink target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/timeonegroup/">
                <IcFacebook />
              </SocialLink>
              <SocialLink target="_blank" rel="noopener noreferrer" href="https://twitter.com/timeonegroup/">
                <IcTwitter />
              </SocialLink>
              <SocialLink target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/timeone/">
                <IcLinkedin />
              </SocialLink>
            </SocialsLinksContainer>
            <Copyright>Copyright © TimeOne</Copyright>
          </Footer>
        </LoginContainer>
        <ImageRight />
        <GlobalStyle />
      </>
    );
  }
}

Login.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Login;
