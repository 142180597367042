import React from 'react';
import { PropTypes } from 'prop-types';

import FormPassword from '../components/FormPassword';

export default function RegisterPassword({ history }) {
  return (
    <FormPassword
      title="Password Creation"
      description={['To complete your registration, please create your password']}
      successTitle="Account Created"
      successDescription="Your account has been successfuly created."
      history={history}
    />
  );
}

RegisterPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
