import { themes } from 'timeone-components';

import colors from './colors';

const { timeone } = themes;

const theme = {
  ...timeone,
  colors: { ...timeone.colors, ...colors },
};

export { colors, theme as default };
