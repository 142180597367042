import React from 'react';
import { themes } from 'timeone-components';

import { LogoStyled } from './styledComponents';

const { timeone } = themes;

export const headerProps = {
  title: '',
  noSidebar: true,
};

export const sidebarProps = {
  entries: [
    {
      name: 'console',
      logo: <LogoStyled src={timeone.header.logo.url} />,
      title: 'Console',
      url: 'https://console.timeonegroup.com/',
    },
  ],
};

export const headerNavbarEntries = [
  {
    name: 'My account',
  },
  {
    name: 'Billing',
  },
];

export const headerNavbarProps = {
  entries: headerNavbarEntries,
  selectedEntry: headerNavbarEntries[0],
};
