import ApolloClient from 'apollo-boost';
import { apolloBoostDefaultConfig, token } from 'timeone-components';

const config = {
  ...apolloBoostDefaultConfig(`${process.env.REACT_APP_AUTHENTIFICATION_SERVER_URL}/oauth`),
  uri: `${process.env.REACT_APP_FOO_API_URL}/graphql`,
  request: operation => {
    operation.setContext({
      headers: {
        Authorization: `${token.get()}`,
      },
    });
  },
};

export const apolloClient = new ApolloClient(config);

export async function handleResponse(response, errorMessage) {
  if (!response.ok) {
    try {
      const { detail, message } = await response.json();

      throw new Error(detail || message || errorMessage);
    } catch (error) {
      throw new Error(error || errorMessage);
    }
  }

  return response.json();
}

export function handleApiCall(apiCall, errorMessage, returnResultFunction = ({ detail, result }) => detail || result) {
  return apiCall
    .then(response => handleResponse(response, errorMessage))
    .then(returnResultFunction)
    .catch(error => {
      console.error(error);
      throw error || errorMessage;
    });
}

export default { apolloClient, handleResponse, handleApiCall };
