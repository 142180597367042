import styled from 'styled-components';
import { Button, Card } from 'timeone-components';

export const ResendEmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResendEmailCard = styled(Card)`
  margin-top: 2rem;
  padding-bottom: 0;
  width: 30rem;
`;

export const ResendEmailPhrasing = styled.div`
  margin-bottom: 1rem;
`;

export const SubmitButton = styled(Button)`
  height: 2.5rem;
  float: right;
`;

export const PopupStatusMessage = styled.div`
  color: ${({ theme, type }) => theme.snackbar[type].backgroundColor};
  flex: 1;
  margin-top: 1rem;
`;
