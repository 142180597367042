import PropTypes from 'prop-types';

const renderButtons = {
  propTypes: {
    pristine: PropTypes.bool.isRequired,
    validating: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
  },
};

export default { renderButtons };
