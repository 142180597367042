import styled from 'styled-components';
import { Paper } from 'timeone-components';

import { ButtonSubmit as BtnSubmit } from '../../../components';

const defaultValues = {
  popuWidth: '32rem',
};

const mobileValues = {
  popopupWidth: 'calc(100% - 5rem)',
};

const getResponsiveValues = query => (query === 'extraSmall' ? mobileValues : defaultValues);

export const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.375);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.25s;
  width: 100%;
  z-index: 1;

  &.fade {
    opacity: 0;
    visibility: collapse;
  }

  &.fade-enter-done {
    opacity: 1;
    visibility: visible;
  }

  &.fade-exit-done {
    opacity: 0;
    visibility: collapse;
  }
`;

export const Popup = styled(Paper).attrs(({ query }) => ({
  responsiveValues: getResponsiveValues(query),
}))`
  padding: 1rem;
  text-align: left;
  width: ${({ responsiveValues }) => responsiveValues.popuWidth};
`;

export const Title = styled.h4`
  font-size: 1.25rem;
  margin: 0.5rem 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Informations = styled.p`
  font-size: 0.875rem;
`;

export const CloseButton = styled.button`
  height: 1rem;
  width: 1rem;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const FormButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 1rem 0 0;
  justify-content: flex-end;
  width: 100%;
`;

export const ButtonSubmit = styled(BtnSubmit)`
  margin: 1rem 0.75rem;
  font-size: 0.75rem;
  padding: 0.5rem 1.5rem;
`;

export const PopupStatusMessage = styled.div`
  color: ${({ theme, type }) => theme.snackbar[type].backgroundColor};
  margin: 0 0.75rem 0 0.75rem;
  flex: 1;
`;
