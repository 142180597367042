import React from 'react';
import PropTypes from 'prop-types';

import { SuccessContainer, CheckIcon, SuccessTitle, ButtonSuccess } from './styledComponents';

const RenderSuccess = ({ successTitle, successDescription, history }) => {
  return (
    <SuccessContainer>
      <CheckIcon />
      <SuccessTitle>{successTitle}</SuccessTitle>
      <p>{successDescription}</p>
      <ButtonSuccess appearance="rounded" fill="true" onClick={() => history.push('/')}>
        Access to the platform
      </ButtonSuccess>
    </SuccessContainer>
  );
};

RenderSuccess.defaultProps = {
  successDescription: [''],
  successTitle: '',
};

RenderSuccess.propTypes = {
  successDescription: PropTypes.arrayOf(PropTypes.string),
  successTitle: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default RenderSuccess;
