import { Snackbar as TolSnackbar, assets } from 'timeone-components';
import styled from 'styled-components';

const { WarningIcon: TolWarningIcon } = assets.icons;

export const Snackbar = styled(TolSnackbar)`
  margin: 0;
  min-height: 2rem;
  box-sizing: content-box;
  padding: 0.5rem 1rem;
  width: calc(100% - 2rem);

  .snackbar__content {
    max-width: 100%;
  }
`;

export const WarningIcon = styled(TolWarningIcon)`
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const ChildrenContainer = styled.span`
  color: ${({ theme }) => theme.colors.white};
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
