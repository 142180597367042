import React, { useState, useRef } from 'react';
import { toast, Form, InputPassword } from 'timeone-components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { userService } from '../../../services';
import RenderButtons from '../RenderButtons';
import RenderSuccess from '../RenderSuccess';
import {
  InputsContainer,
  PasswordCreationCard,
  PasswordValidityConditions,
  FormPasswordContainer,
  FormPasswordHeader,
  InformationIcon,
} from './styledComponents';

const RenderCreatePassword = ({ title, description, successTitle, successDescription, history }) => {
  const [shouldDisplaySuccess, setShouldDisplaySuccess] = useState(false);
  const passwordRef = useRef();
  const location = useLocation();

  const handleSubmit = async ({ password }) => {
    try {
      const searchParams = new URLSearchParams(location.search);

      if (!searchParams.has('token') && !searchParams.has('userId')) {
        throw new Error('Cannot find user informations.');
      }

      const token = searchParams.get('token');
      const userId = searchParams.get('userId');

      await userService.editUserPassword({
        authorization: token,
        userId,
        password,
      });

      return setShouldDisplaySuccess(true);
    } catch (errorSubmit) {
      return toast.error(errorSubmit.message);
    }
  };
  const validatePassword = value => {
    function createErrorMessage(condition) {
      return `Password must contain at least ${condition}.`;
    }

    if (value.length < 12) {
      return createErrorMessage('12 characters');
    }

    if (!/^(?=.*\W)[a-zA-Z\d\W]/.test(value)) {
      return createErrorMessage('1 special character');
    }

    if (!/^(?=.*[A-Z])[a-zA-Z\d\W]/.test(value)) {
      return createErrorMessage('1 uppercase character');
    }

    if (!/^(?=.*[a-z])[a-zA-Z\d\W]/.test(value)) {
      return createErrorMessage('1 lowercase character');
    }

    if (!/^(?=.*\d)[a-zA-Z\d\W]/.test(value)) {
      return createErrorMessage('1 number');
    }

    return undefined;
  };

  const validateConfirmPassword = value => {
    if (value !== passwordRef.current) {
      return 'Password and confirm password should be the same.';
    }

    return undefined;
  };

  const handlePasswordChange = e => {
    passwordRef.current = e.target.value;
  };

  return !shouldDisplaySuccess ? (
    <FormPasswordContainer>
      <FormPasswordHeader title={title} descriptions={description} />
      <Form onSubmit={handleSubmit} renderButtons={RenderButtons}>
        <PasswordCreationCard>
          <PasswordValidityConditions>
            <InformationIcon />
            <div>
              <p>These data are strickly personal. In no case they will be shared. </p>
              <br />
              <br />
              <p>Password must contain at least : </p>
              <li>- 12 characters</li>
              <li>- 1 special character</li>
              <li>- 1 uppercase character</li>
              <li>- 1 lowercase character</li>
              <li>- 1 number</li>
              <br />
              <p>Please note that your password can not be : </p>
              <li>- one of the last eight passwords</li>
              <li>- your surname</li>
              <li>- your firstname</li>
              <li>- your email</li>
              <li>- a resource name (advertiser, publisher, program, ...)</li>
            </div>
          </PasswordValidityConditions>
          <InputsContainer>
            <InputPassword
              autocomplete="new-password"
              label="Password"
              name="password"
              onChange={handlePasswordChange}
              required
              validation={validatePassword}
              validateFields={['confirmPassword']}
              allowPaste
            />
            <InputPassword
              autocomplete="new-password"
              label="Confirm Password"
              name="confirmPassword"
              required
              validation={validateConfirmPassword}
              allowPaste
            />
          </InputsContainer>
        </PasswordCreationCard>
      </Form>
    </FormPasswordContainer>
  ) : (
    <RenderSuccess successTitle={successTitle} successDescription={successDescription} history={history} />
  );
};

RenderCreatePassword.defaultProps = {
  description: [''],
  title: '',
  successTitle: '',
  successDescription: '',
};

RenderCreatePassword.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  successTitle: PropTypes.string,
  successDescription: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default RenderCreatePassword;
