import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'timeone-components';

const ButtonSubmit = ({ invalid, submitting, disabled, children, ...props }) => {
  return (
    <Button {...props} submitting={submitting} type="submit" disabled={invalid || submitting || disabled}>
      {children}
    </Button>
  );
};

ButtonSubmit.defaultProps = {
  disabled: false,
  invalid: false,
  submitting: false,
};

ButtonSubmit.propTypes = {
  invalid: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ButtonSubmit;
