import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '../Snackbar';
import { DescriptionsContainer, Title } from './styledComponents';

const Header = ({ descriptions, className, error, title }) => (
  <div className={className}>
    <Title>{title}</Title>
    {error ? (
      <Snackbar type="error">{error}</Snackbar>
    ) : (
      <DescriptionsContainer>
        {descriptions.map(descriptionLine => (
          <p key={descriptionLine.trim().slice(0, 8)}>{descriptionLine}</p>
        ))}
      </DescriptionsContainer>
    )}
  </div>
);

Header.defaultProps = {
  className: '',
  error: null,
};

Header.propTypes = {
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Header;
