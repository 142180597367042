import React from 'react';
import PropTypes from 'prop-types';

import RenderCreatePassword from '../RenderCreatePassword';
import ResendEmail from '../ResendEmail';

const DisplayForm = ({ title, description, successTitle, successDescription, isTokenValid, history }) => {
  return isTokenValid ? (
    <RenderCreatePassword
      title={title}
      description={description}
      successTitle={successTitle}
      successDescription={successDescription}
      history={history}
    />
  ) : (
    <ResendEmail />
  );
};

DisplayForm.defaultProps = {
  description: [''],
  title: '',
  successTitle: '',
  successDescription: '',
  isTokenValid: false,
};

DisplayForm.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  successTitle: PropTypes.string,
  successDescription: PropTypes.string,
  isTokenValid: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default DisplayForm;
