import React from 'react';
import PropTypes from 'prop-types';

import { ChildrenContainer, Snackbar, WarningIcon } from './styledComponents';

function SnackBar(props) {
  const { children, type } = props;

  return (
    <Snackbar {...props}>
      <>
        {type === 'error' && <WarningIcon />}
        <ChildrenContainer>{children}</ChildrenContainer>
      </>
    </Snackbar>
  );
}

SnackBar.defaultProps = {
  type: '',
};

SnackBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  type: PropTypes.string,
};

export default SnackBar;
