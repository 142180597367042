export default {
  primary: '#303747',
  secondary: '#FFC844',
  fontColor: '#303747',
  // business units
  mobile: '#FFA143',
  performance: '#EB0045',
  leadManagementTechnology: '#65B2E8',

  // colors
  black: '#000',
  white: '#fff',
  gray: '#868686',
};
