import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import theme from '../theme';
import RegisterPassword from '../pages/RegisterPassword';
import Login from '../pages/Login';
import { headerProps } from './layoutData';
import { Layout } from './styledComponents';
import ForgotPassword from '../pages/ForgotPassword';

const renderWithApplicationLayout = Component => routeProps => (
  <Layout className="App" headerProps={headerProps} withBreadcrumb={false}>
    <Component {...routeProps} />
  </Layout>
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/register-password" render={renderWithApplicationLayout(RegisterPassword)} />
          <Route path="/change-password" render={renderWithApplicationLayout(ForgotPassword)} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
