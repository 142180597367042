import { handleApiCall } from '../utils';
import { validateTokenApi, patchUser, postUser, postUserPasswordReset } from './user.api';

export const createUser = ({ authorization, firstName, lastName, email }) =>
  handleApiCall(postUser({ authorization, firstName, lastName, email }), 'Error when creating account.');

export const resetPassword = email => handleApiCall(postUserPasswordReset(email), 'Cannot reset password.');

export const resendPasswordCreationMail = email =>
  handleApiCall(postUserPasswordReset(email), 'Cannot resend password creation mail.');

export const editUserPassword = ({ authorization, userId, password }) =>
  handleApiCall(
    patchUser({ authorization, userId, password: encodeURIComponent(password) }),
    'Cannot register password.'
  );

export async function validateToken(userId, tokenId) {
  const response = await validateTokenApi(userId, tokenId);

  if (!response.ok) {
    const { detail } = await response.json();

    throw new Error(detail);
  }

  return response.json();
}

export default { createUser, editUserPassword, resetPassword, resendPasswordCreationMail, validateToken };
