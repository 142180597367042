import React from 'react';

import commonPropTypes from '../../../utils/commonPropTypes';
import { ButtonConfirm } from './styledComponents';

const RenderButtons = ({ submitting, pristine, invalid, hasValidationErrors, dirtySinceLastSubmit }) => {
  return (
    <ButtonConfirm
      appearance="rounded"
      disabled={hasValidationErrors || submitting || pristine || (invalid && !dirtySinceLastSubmit)}
      fill="true"
      type="submit"
    >
      Confirm Your Password
    </ButtonConfirm>
  );
};

RenderButtons.propTypes = commonPropTypes.renderButtons.propTypes;

export default RenderButtons;
