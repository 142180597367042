import styled from 'styled-components';
import { ApplicationLayout, Button } from 'timeone-components';

export const ButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.colors.fontColors};
`;

export const Layout = styled(ApplicationLayout)`
  .default-layout__content .default-layout__children {
    padding: 0;
  }
`;

export const LogoStyled = styled.img`
  height: 2.2rem;
  width: 2.2rem;
`;
