import React, { useState } from 'react';
import { Form, InputText } from 'timeone-components';

import { userService } from '../../../services';
import {
  PopupStatusMessage,
  ResendEmailCard,
  ResendEmailContainer,
  ResendEmailPhrasing,
  SubmitButton,
} from './styledComponents';
import validateMail from '../../../utils/validateMail';

const ResendEmail = () => {
  const [statusMessageType, setStatusMessageType] = useState(null);
  const [statusMessage, setStatusMessage] = useState();

  const handleSubmit = async forgotEmail => {
    try {
      const result = await userService.resetPassword(forgotEmail);

      if (result === 'success') {
        setStatusMessageType('success');
        setStatusMessage(
          `We sent a link to ${forgotEmail.email} so you can pick your new password. 
          Didn’t get the email? Check your email address.`
        );
        return undefined;
      }
      throw new Error('Error when trying to send a resetPassword.');
    } catch (error) {
      console.error(error.message);
      setStatusMessageType('error');
      setStatusMessage('An error occured. Please try again.');
      return { errors: error.message };
    }
  };

  return (
    <ResendEmailContainer>
      <ResendEmailCard title="Password reset">
        <Form
          onSubmit={handleSubmit}
          renderButtons={({ invalid, pristine, submitting }) => {
            return (
              <SubmitButton type="submit" appearance="rounded" disabled={invalid || pristine || submitting} fill>
                Send email
              </SubmitButton>
            );
          }}
        >
          <ResendEmailPhrasing>
            <p>The link has expired, please enter your email to receive a new one :</p>
          </ResendEmailPhrasing>
          {statusMessageType && statusMessageType === 'success' ? (
            <PopupStatusMessage type={statusMessageType}>{statusMessage}</PopupStatusMessage>
          ) : null}
          <InputText
            type="email"
            label="Email"
            name="email"
            className="input-text"
            validation={validateMail}
            required
          />

          {statusMessageType && statusMessageType === 'error' ? (
            <PopupStatusMessage type={statusMessageType}>{statusMessage}</PopupStatusMessage>
          ) : null}
        </Form>
      </ResendEmailCard>
    </ResendEmailContainer>
  );
};

export default ResendEmail;
