import { authenticate } from './authentication.api';
import { handleApiCall } from '../utils';

export const loginUser = async ({ email, password }) =>
  handleApiCall(
    authenticate({ email, password: encodeURIComponent(password) }),
    'Error when trying to authenticate user.',
    result => result
  );

export default { loginUser };
