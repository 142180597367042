import styled from 'styled-components';

import ButtonSubmit from '../ButtonSubmit';

export const ButtonConfirm = styled(ButtonSubmit)`
  background-color: ${({ theme }) => theme.snackbar.success.backgroundColor};
  font-size: 1rem;
  margin: 2rem auto;
  transition: all 0.25s;

  &:hover {
    background-color: ${({ theme }) => theme.snackbar.success.backgroundColor};
    opacity: 0.75;
  }

  &:disabled {
    color: ${({ theme }) => theme.button.disabled.fontColor.primary};
    background-color: ${({ theme }) => theme.button.disabled.backgroundColor.primary};
  }
`;
