import React from 'react';
import { PropTypes } from 'prop-types';

import FormPassword from '../components/FormPassword';

export default function ForgotPassword({ history }) {
  return (
    <FormPassword
      title="Reset password"
      description={['Please create your new password below']}
      successDescription="Password Reset"
      successTitle="Your password has been successfuly changed."
      history={history}
    />
  );
}

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
