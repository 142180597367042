import React from 'react';

export const Message = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
  </svg>
);

export const TimeoneLarge = () => (
  <svg
    id="Calque_1"
    data-name="Calque 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 455.07 141.95"
    height="4.25rem"
  >
    <g>
      <path
        className="cls-2"
        fill="#ea5b0c"
        strokeWidth="0"
        d="M295.07,11.44c-20.39,0-34.64,12.17-34.64,29.61,0,14.26,10.84,29.61,34.64,29.61s34.64-15.35,
        34.64-29.61c0-17.15-14.57-29.61-34.64-29.61ZM295.12,59.52c-12.84,0-21.14-7.23-21.14-18.42s8.3-18.52,
        21.14-18.52c14.68,0,21.25,9.3,21.25,18.52,0,11.02-8.54,18.42-21.25,18.42Z"
      />
      <path
        className="cls-2"
        fill="#ea5b0c"
        strokeWidth="0"
        d="M428.55,20.56c-18.9,0-28.79,12.68-28.79,25.2,0,15.78,10.96,25.2,29.32,25.2,7.25,0,13.81-1.33,
        20.03-4.08l.28-.12-4.88-9.23-.25.13c-6.04,3.16-13.12,3.32-14.47,3.32-8.76,
        0-15.18-3.64-17.28-9.76h42.27l.07-.74c.12-1.27.22-2.47.22-3.76,0-15.4-10.9-26.16-26.51-26.16ZM442.35,
        41.44h-30.23c.92-3.54,4.2-10.9,15.56-10.9,9.13,0,13.54,5.57,14.67,10.9Z"
      />
      <path
        className="cls-1"
        fill="#000"
        strokeWidth="0"
        d="M224.49,19.56c-19.55,0-29.78,13.11-29.78,26.06,0,16.32,11.34,26.07,30.33,26.07,7.5,0,14.28-1.38,
        20.72-4.22l.29-.13-5.05-9.55-.26.14c-6.25,3.27-13.57,3.44-14.97,3.44-9.06,
        0-15.71-3.77-17.87-10.09h43.73l.07-.77c.12-1.31.23-2.55.23-3.89,0-15.93-11.28-27.06-27.42-27.06ZM238.76,
        41.16h-31.27c.95-3.67,4.34-11.28,16.09-11.28,9.44,0,14.01,5.76,15.18,11.28Z"
      />
      <g>
        <path
          className="cls-1"
          fill="#000"
          strokeWidth="0"
          d="M144.42,28.44c2.49-2.62,5.36-4.71,8.74-5.95,8.81-3.24,17.43-3.14,25.5,2.09,4.52,2.92,6.98,7.33,7.06,
          12.71.17,10.88.09,21.76.11,32.64,0,.22-.05.44-.08.74h-13.38c0-.56,0-1.1,0-1.63,0-9.21,0-18.43,0-27.64,
          0-3.98-1.23-7.35-5.02-9.17-5.36-2.58-14.53-2.01-19.44,4.28-.35.45-.48,1.17-.49,1.77-.02,10.46-.01,20.93,0,
          31.39,0,.31-.03.63-.05,1.01h-13.4c-.03-.53-.08-1.06-.08-1.59,0-8.98.02-17.96-.03-26.95,
          0-1.56-.1-3.18-.52-4.66-.93-3.33-3.33-5.3-6.63-6.04-6.47-1.46-12.33-.44-16.97,4.72-.49.55-.81,1.46-.81,
          2.21-.05,10.19-.04,20.37-.04,30.56,0,.55,0,1.09,0,1.74h-13.37V21.78h11.15c.39,1.93.79,3.85,1.2,5.87,
          9.34-10.06,28.94-9.48,36.53.79Z"
        />
        <path
          className="cls-2"
          fill="#ea5b0c"
          strokeWidth="0"
          d="M352.08,70.66h-13.42V21.77h11.18c.3,1.83.6,3.64.93,5.63.43-.33.74-.56,1.04-.8,5.9-4.89,12.67-6.8,
          20.28-6.02,5.16.53,9.82,2.18,13.59,5.86,2.91,2.83,4.67,6.36,4.73,10.35.17,11.24.06,22.48.06,33.83h-13.4c0-.58,
          0-1.11,0-1.65,0-8.93.01-17.87-.02-26.8,0-1.34-.06-2.7-.35-4-.81-3.65-3.2-5.91-6.78-6.74-6.4-1.48-12.21-.48-16.93,
          4.52-.67.71-.96,1.46-.96,2.46.04,10.18.02,20.37.02,30.55v1.7Z"
        />
        <path
          className="cls-1"
          fill="#000"
          strokeWidth="0"
          d="M62.12,11.82v11.58h-24.1v47.22h-13.97V23.46H0v-11.64h62.12Z"
        />
        <path className="cls-1" fill="#000" strokeWidth="0" d="M70.78,70.55V21.74h13.32v48.81h-13.32Z" />
        <path
          className="cls-1"
          fill="#000"
          strokeWidth="0"
          d="M77.44,13.87c3.95,0,7.25-3.11,7.25-6.98s-3.39-6.89-7.25-6.89-7.25,2.92-7.25,6.89,3.37,6.98,7.25,6.98Z"
        />
      </g>
    </g>
    <path
      className="cls-1"
      fill="#000"
      strokeWidth="0"
      d="M177.28,113.49c3,0,7.24.96,11.18,4.05,0,0,4.99-5.53,4.99-5.53-5.4-4.49-12.9-5.3-16.17-5.3-8.06,0-14.14,
      4.36-14.14,10.26,0,1.24-.43,8.03,11.59,10.61,10.06,2.16,10.38,3.29,10.38,4.91s-1.48,1.95-2.01,
      2.12c-.97.31-2.3.53-4.1.53-5.61,0-10.27-2.36-13.13-4.47l-5.1,5.79c3.89,2.99,10.8,5.46,17.17,5.46,7.69,0,
      15.01-2.5,15.01-10.24,0-2.4-.44-7.5-11.55-10.12-8.49-2.01-10.37-3.04-10.37-5.05,0-2.3,3.58-3.02,6.24-3.02Z"
    />
    <path
      className="cls-1"
      fill="#000"
      strokeWidth="0"
      d="M254.85,113.49c3,0,7.24.96,11.18,4.05,0,0,4.99-5.53,4.99-5.53-5.4-4.49-12.9-5.3-16.17-5.3-8.06,
      0-14.14,4.36-14.14,10.26,0,1.24-.43,8.03,11.59,10.61,10.06,2.16,10.38,3.29,10.38,4.91,0,2.4-3.94,2.65-6.1,
      2.65-5.61,0-10.27-2.36-13.13-4.47l-5.1,5.79c3.89,2.99,10.8,5.46,17.17,5.46,7.69,0,15.01-2.5,15.01-10.24,
      0-2.4-.44-7.5-11.55-10.12-8.49-2.01-10.37-3.04-10.37-5.05,0-2.3,3.58-3.02,6.24-3.02Z"
    />
    <path
      className="cls-1"
      fill="#000"
      strokeWidth="0"
      d="M216.41,106.7c-12.6,0-20.6,7.78-20.6,17.62,0,9.38,7.48,17.62,20.6,17.62,13.14,0,20.6-8.23,20.6-17.62,
      0-9.83-8.04-17.62-20.6-17.62ZM228.92,124.36c0,6.3-4.84,10.8-12.47,10.8-8.08,0-12.41-4.82-12.41-10.8,0-6.2,
      4.49-10.86,12.41-10.86,8.34,0,12.47,5.13,12.47,10.86Z"
    />
    <path
      className="cls-1"
      fill="#000"
      strokeWidth="0"
      d="M332.2,106.69c-12.67,0-20.16,8.34-20.16,17.62,0,9.9,6.53,17.62,20.53,17.62,3.91,0,8.77-.52,
      14.08-2.87l-3.26-6.16c-3.87,2.03-8.38,2.37-10.32,2.37-6.46,0-11.08-2.73-12.49-7.3h29.98c.09-1.01.19-1.96.19-2.98,
      0-11.18-8.15-18.3-18.55-18.3ZM342.2,121.46h-21.88c1.03-4.43,4.59-8.11,11.26-8.11s9.9,4.23,10.62,8.11Z"
    />
    <path
      className="cls-2"
      fill="#ea5b0c"
      strokeWidth="0"
      d="M341.14,96.74h0c0,1.83-1.49,3.32-3.32,3.32h-13.11c-1.83,0-3.32-1.49-3.32-3.32h0c0-1.83,1.49-3.32,
      3.32-3.32h13.11c1.83,0,3.32,1.49,3.32,3.32Z"
    />
    <path
      className="cls-2"
      fill="#ea5b0c"
      strokeWidth="0"
      d="M152.23,101.63c2.78,0,5.1-2.18,5.1-4.91s-2.38-4.85-5.1-4.85-5.1,2.05-5.1,4.85,2.37,4.91,5.1,4.91Z"
    />
    <rect className="cls-1" x="148.31" y="107.51" width="7.89" height="33.6" />
    <polygon
      className="cls-1"
      fill="#000"
      strokeWidth="0"
      points="295.84 122.79 312.03 107.51 301.46 107.51 288.45 119.96 283.61 119.96 283.61 96.74 275.66 96.74 275.66
      141.11 283.61 141.11 283.61 126.79 289.3 126.79 302.37 141.11 312.87 141.11 295.84 122.79"
    />
    <rect className="cls-1" x="356.15" y="96.74" width="7.95" height="44.37" />
    <g>
      <path
        className="cls-1"
        fill="#000"
        strokeWidth="0"
        d="M411.96,104.03l12.08,5.49h18.5l1.53-2.66c.92-1.59-.19-3.51-1.87-3.51h-30.11c-.41,0-.51.5-.13.67Z"
      />
      <path
        className="cls-1"
        fill="#000"
        strokeWidth="0"
        d="M424.04,111.88h30.14c.35,0,.69.27.69.68,0,.36-.25.59-.5.66l-54.53,14.77c-.36.1-.61-.36-.31-.6l24.51-15.51Z"
      />
      <path
        className="cls-1"
        fill="#000"
        strokeWidth="0"
        d="M408.82,128l19.78-.37c1.92-.04,4.17-1.14,5.19-2.92l2.37-4.12-27.35,7.41Z"
      />
    </g>
    <path
      className="cls-1"
      fill="#000"
      strokeWidth="0"
      d="M400.86,132.9c-2.21,1.19-6.19,2.37-10.32,2.37-3.85,0-10.48-1.06-12.5-7.3h11.3l10.3-6.52h-21.85c.94-4.14,
      4.3-8.11,11.26-8.11,8.41,0,10.43,6.54,10.62,8.1l6.94-4.39c-1.51-3.71-6.64-10.35-16.93-10.35-3.01,0-13.8.42-18.7,
      10.7-1.01,2.12-2.73,7.92,0,14.38,2.33,5.5,8.31,10.14,19.08,10.14,7.38,0,11.89-1.91,14.08-2.86l-3.26-6.16Z"
    />
  </svg>
);

export const IcTwitter = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" {...props}>
    <circle fill="#EA5B0C" cx="60" cy="60" r="60" />
    <path
      fill="#FFFFFF"
      d="M49.1,91.5c26.5,0,41-22,41-41c0-0.6,0-1.2,0-1.8c2.8-2,5.3-4.6,7.2-7.5c-2.6,1.1-5.4,1.9-8.3,2.3
c3-1.8,5.3-4.6,6.4-8c-2.8,1.7-5.9,2.8-9.2,3.5c-2.6-2.8-6.4-4.6-10.5-4.6c-8,0-14.4,6.5-14.4,14.4c0,1.1,0.1,2.2,0.4,3.3
C49.6,51.5,39,45.7,31.9,37c-1.2,2.1-1.9,4.6-1.9,7.2c0,5,2.5,9.4,6.4,12c-2.4-0.1-4.6-0.7-6.5-1.8c0,0,0,0.1,0,0.2
c0,7,5,12.8,11.6,14.1c-1.2,0.3-2.5,0.5-3.8,0.5c-0.9,0-1.8-0.1-2.7-0.3c1.8,5.7,7.2,9.9,13.5,10c-5,3.9-11.2,6.2-17.9,6.2
c-1.1,0-2.3,0-3.4-0.2C33.4,89.1,41,91.5,49.1,91.5"
    />
  </svg>
);

export const IcFacebook = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" {...props}>
    <circle fill="#EA5B0C" cx="60" cy="60" r="60" />
    <path
      fill="#FFFFFF"
      d="M44.5,52.1c2.9,0,6,0,9.1,0C53.5,46,50,27.7,73.2,30.4c0.5,0,1.3,0.1,2.4,0.2v9.5h-5.9c-1.9-0.1-3.3,0.4-4,1.4
      c-0.8,1-1.1,2.4-1.1,4.4V52h10.6l-1.3,10.6h-9.2l0.1,27.2H53.7V62.7h-9.2V52.1z"
    />
  </svg>
);
export const IcLinkedin = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" {...props}>
    <circle fill="#EA5B0C" cx="60" cy="60" r="60" />
    <path
      fill="#FFFFFF"
      d="M83.9,82.8h-9.7V67.5c0-3.7-0.1-8.3-5.1-8.3c-5.1,0-5.9,4-5.9,8.1v15.5h-9.7V51.4h9.4v4.3H63
      c1.3-2.5,4.5-5.1,9.2-5.1c9.9,0,11.7,6.5,11.7,15L83.9,82.8L83.9,82.8z M42.5,47.1c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.6,5.7-5.6
      s5.6,2.5,5.6,5.6C48.1,44.6,45.6,47.1,42.5,47.1z M37.6,82.8h9.7V51.4h-9.7V82.8z"
    />
  </svg>
);

export default { Message, TimeoneLarge, IcLinkedin, IcFacebook, IcTwitter };
